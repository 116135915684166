import { getMessageError, getMessageHeader } from "@/core/helpers/messageFromPulmeros";
import { translate } from "@/core/plugins/i18n";
import PaginationRequest from "@/models/general/PaginationRequest";
import PaginationResponse from "@/models/general/PaginationResponse";
import TablePaginationRequest from "@/models/general/TablePaginationRequest";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import CrudService from "./interfaces/CrudService";

/**
 * Catalogo generico para poder heredar los metodos genericos de un catalog, hay que pasar
 * 
 */
export default class CatalogService<RowModel, FormModel> implements GeneralService, CrudService<FormModel, FormModel> {

    apiController: string;
    messageService: MessageService;

    /**
     * constructor
     * @param apiController controlador
     * @param messageService servicio de mensaje
     */
    constructor(apiController: string, messageService: MessageService){
        this.apiController = apiController;
        this.messageService = messageService;
    }
    async getInfo(id: string): Promise<AxiosResponse<FormModel>> {
        return  ApiService.get<FormModel>(this.apiController, 
            `${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    async save(form: FormModel): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController
            , form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch((error) => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
    async update(id: string, newInfo: FormModel): Promise<AxiosResponse<boolean>> {
        return  ApiService.update<boolean>(this.apiController 
            , id
            , newInfo
        ).then( resp => {
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * Busca los datos de la tabla de notas
     * @param request datos de request
     * @returns datos de paginado con los datos de la tabla
     */
    async getTableInfo(request: TablePaginationRequest): Promise<AxiosResponse<PaginationResponse<RowModel>>> {
        return  ApiService.get<PaginationResponse<RowModel>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${request.currentPage}`
            + `&PageSize=${request.pageSize}`
            + (request.name != '' ? `&Criteria.Name=${request.name}` : '')
            + (request.active != undefined ? `&Criteria.Active=${request.active}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * Busca los datos de la tabla mediante los filtros dinamicos
     * @param request 
     */
    async getTableInfoDynamicFilters(request: any): Promise<AxiosResponse<PaginationResponse<RowModel>>> {
        let filtersQuery = '';
        const propsToUpdate = Object.keys(request);
        propsToUpdate.forEach(propName => {
            if (request[propName]) { 
                filtersQuery+= `&Criteria.${propName}=${request[propName]}`
            } else if (typeof request[propName] == "boolean") {
                filtersQuery+= `&Criteria.${propName}=${request[propName]}`
            }
        })

        return ApiService.get<PaginationResponse<RowModel>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${request?.currentPage ?? 1}`
            + `&PageSize=${request?.pageSize ?? 10}`
            + (filtersQuery != '' ? filtersQuery : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
     }

    /**
     * Cambia el estado del registro
     * @param id identificador del registro
     * @param status indica si se activa o inactiva
     * @returns indica si se hizo el cambio
     */
    async changeStatus(id: string, status: boolean): Promise<AxiosResponse<boolean>> { 
         return  ApiService.update<boolean>(this.apiController, 
             `${id}/${status ? 'activate' : 'deactivate'}`, {}
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}